import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Passport, PassportResponse } from "../types/types";

export const passportApi = createApi({
  reducerPath: "passportApi",
  refetchOnFocus: true,
  refetchOnReconnect: true,
  baseQuery: fetchBaseQuery({
    baseUrl: "/ps/passport",
  }),
  tagTypes: ["passport", "passportConfig"],
  endpoints: (build) => ({
    //passport
    addPassport: build.mutation<PassportResponse, Passport>({
      query: (body) => ({
        url: "/",
        method: "POST",
        body,
      }),
      invalidatesTags: ["passport"],
    }),
    updatePassport: build.mutation<void, Passport & { ID: number }>({
      query: (body) => ({
        url: "/",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["passport"],
    }),
    deletePassport: build.mutation<void, number>({
      query: (passportId) => ({
        url: `/${passportId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["passport"],
    }),
    deletePassportByTwinID: build.mutation<void, number>({
      query: (twinId) => ({
        url: `/twin/${twinId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["passport"],
    }),
  }),
});

export const {
  useAddPassportMutation,
  useDeletePassportMutation,
  useDeletePassportByTwinIDMutation,
  useUpdatePassportMutation,
} = passportApi;
