import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import {
  DocModuleSearchRequestBody,
  ExtractedDoc,
  ExtractedDocDatabaseModel,
  ExtractedDocResponse
} from "../types/types";

export const docModuleApi = createApi({
  reducerPath: "docModuleApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "/dm",
  }),
  tagTypes: ["DocModule", "DocumentRules"],
  refetchOnFocus: true,
  refetchOnReconnect: true,
  endpoints: (build) => ({
    // to add the document to the database; returns the uid
    addDocument: build.mutation<
      { id: string },
      { documentType: string; file: string; contentType: string; fileName?: string }
    >({
      query({ documentType, file, contentType, fileName }) {
        return {
          url: "/document/extract",
          method: "POST",
          body: {
            documentType,
            file,
            contentType,
            fileName,
          },
        };
      },
      invalidatesTags: ["DocModule"],
    }),
    getDocModuleById: build.query<ExtractedDocDatabaseModel, string>({
      query: (id) => `document/${id}`,
      providesTags: ["DocModule"],
    }),
    updateDocModuleData: build.mutation<void, { id: string; content: Partial<ExtractedDoc> }>({
      query(input) {
        return {
          url: `/document/${input.id}`,
          method: "PUT",
          body: {
            content: input.content,
          },
        };
      },
      invalidatesTags: ["DocModule"],
    }),
    getDocumentsByFilter: build.query<ExtractedDocResponse, DocModuleSearchRequestBody>({
      query: (params) => ({
        url: "/document/search",
        method: "POST",
        body: params,
      }),
      providesTags: ["DocModule"],
    }),
    deleteDocModule: build.mutation<void, { id: string }>({
      query(body) {
        return {
          url: `/document/${body.id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["DocModule"],
    }),

    // querying file db
    getDocModuleFile: build.query<Blob, { id: string }>({
      query: (body) => ({
        url: `/file/${body.id}`,
        responseHandler: async (response) => {
          const base64String = await response.text();
          const byteArray = Uint8Array.from(atob(base64String), (c) => c.charCodeAt(0));
          const blob = new Blob([byteArray], { type: "application/pdf" });
          return blob;
        },
      }),
      providesTags: ["DocModule"],
    }),

    // querying xlsx export
    getDocModuleXls: build.query<Blob, { id: string }>({
      query: (body) => ({
        url: `/xls/${body.id}`,
        responseHandler: async (response) => {
          const base64String = await response.text();
          const byteArray = Uint8Array.from(atob(base64String), (c) => c.charCodeAt(0));
          const blob = new Blob([byteArray], { type: "application/vnd.ms-excel" });
          return blob;
        },
      }),
      providesTags: ["DocModule"],
    }),
    getDocModuleXlsOverview: build.query<Blob, void>({
      query: () => ({
        url: "/xls/overview",
        responseHandler: async (response) => {
          const base64String = await response.text();
          const byteArray = Uint8Array.from(atob(base64String), (c) => c.charCodeAt(0));
          const blob = new Blob([byteArray], { type: "application/vnd.ms-excel" });
          return blob;
        },
      }),
      providesTags: ["DocModule"],
    }),
    updateTags: build.mutation<void, { id: string; tags: string[] }>({
      query(body) {
        return {
          url: `/document/tags/${body.id}`,
          method: "PUT",
          body: {
            tags: body.tags,
          },
        };
      },
      invalidatesTags: ["DocModule"],
    }),
  }),
});

export const {
  useAddDocumentMutation,
  useGetDocModuleByIdQuery,
  useLazyGetDocModuleByIdQuery,
  useUpdateDocModuleDataMutation,
  useUpdateTagsMutation,
  useDeleteDocModuleMutation,

  // xlsx
  useGetDocumentsByFilterQuery,
  useLazyGetDocumentsByFilterQuery,
  useLazyGetDocModuleXlsQuery,
  useLazyGetDocModuleXlsOverviewQuery,

  // file db
  useLazyGetDocModuleFileQuery,
} = docModuleApi;
